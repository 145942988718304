<template>
  <div>
    <Header />
    <br /><br /><br /><br /><br /><br /><br />
    <div class="container fluid">
      <div class="row" style="margin-top: 50px">
        <div class="col-md-6 col-xl-3">
          <!-- Simple card -->
          <router-link
            to="/production_equipement/wave_soldering/powerflow_ultra_xxl"
          >
            <div class="card" style="width: 260px; height: 240px">
              <img
                style="max-height: 80%; max-width: 80%; margin: auto"
                class="card-img-top img-fluid"
                src="../../../../assets/images/product/main/production_equipement/Wave_Soldering/1csm_Ersa-Wave-POWERFLOWULTRAXXL-01-900x675_650ac69949.png"
                alt="Card image cap"
              />

              <div class="card-body">
                <h4 style="text-align: center" class="card-title">
                  POWERFLOW ULTRA XXL
                </h4>
                <h6
                  style="text-align: center; font-size: 10px"
                  class="card-title"
                >
                  High-end full-tunnel nitrogen wave soldering system for
                  working widths up to 610…
                </h6>
              </div>
            </div>
          </router-link>
        </div>
        <!-- end col -->
        <div class="col-md-6 col-xl-3">
          <!-- Simple card -->
          <router-link
            to="/production_equipement/wave_soldering/powerflow_ultra_xl"
          >
            <div class="card" style="width: 260px; height: 240px">
              <img
                style="max-height: 80%; max-width: 80%; margin: auto"
                class="card-img-top img-fluid"
                src="../../../../assets/images/product/main/production_equipement/Wave_Soldering/2csm_Ersa-Wave-POWERFLOWULTRAXL-01-900x675_77100792d2.png"
                alt="Card image cap"
              />
              <div class="card-body">
                <h4 style="text-align: center" class="card-title">
                  POWERFLOW ULTRA XL
                </h4>
                <h6
                  style="text-align: center; font-size: 10px"
                  class="card-title"
                >
                  High-end full tunnel nitrogen wave soldering system for
                  working widths up to 520…
                </h6>
              </div>
            </div>
          </router-link>
        </div>
        <!-- end col -->
        <div class="col-md-6 col-xl-3">
          <!-- Simple card -->
          <router-link
            to="/production_equipement/wave_soldering/powerflow_ultra"
          >
            <div class="card" style="width: 260px; height: 240px">
              <img
                style="max-height: 80%; max-width: 80%; margin: auto"
                class="card-img-top img-fluid"
                src="../../../../assets/images/product/main/production_equipement/Wave_Soldering/3csm_Ersa-Wave-POWERFLOWULTRA-01-900x675_e1f30ae5a1.png"
                alt="Card image cap"
              />
              <div class="card-body">
                <h4 style="text-align: center" class="card-title">
                  POWERFLOW ULTRA
                </h4>
                <h6
                  style="text-align: center; font-size: 10px"
                  class="card-title"
                >
                  High-End Full Tunnel Nitrogen wave soldering system
                </h6>
              </div>
            </div>
          </router-link>
        </div>
        <div class="col-md-6 col-xl-3">
          <!-- Simple card -->
          <router-link
            to="/production_equipement/wave_soldering/powerflow_pro"
          >
            <div class="card" style="width: 260px; height: 240px">
              <img
                style="max-height: 80%; max-width: 80%; margin: auto"
                class="card-img-top img-fluid"
                src="../../../../assets/images/product/main/production_equipement/Wave_Soldering/4csm_Ersa-Wave-POWERFLOWPRO-01-900x675_b156afbd8a.png"
                alt="Card image cap"
              />
              <div class="card-body">
                <h4 style="text-align: center" class="card-title">
                  POWERFLOW PRO
                </h4>
                <h6
                  style="text-align: center; font-size: 10px"
                  class="card-title"
                >
                  Compact full tunnel wave soldering system for cost-optimized
                  wave soldering
                </h6>
              </div>
            </div>
          </router-link>
        </div>
        <!-- end col -->
      </div>
      <div class="row" style="margin-top: 30px">
        <div class="col-md-6 col-xl-3">
          <!-- Simple card -->
          <router-link
            to="/production_equipement/wave_soldering/powerflow"
          >
            <div class="card" style="width: 260px; height: 240px">
              <img
                style="max-height: 80%; max-width: 80%; margin: auto"
                class="card-img-top img-fluid"
                src="../../../../assets/images/product/main/production_equipement/Wave_Soldering/5csm_Ersa-Wave-POWERFLOW-01-900x675_56d1c7c31f.png"
                alt="Card image cap"
              />
              <div class="card-body">
                <h4 style="text-align: center" class="card-title">POWERFLOW</h4>
                <h6
                  style="text-align: center; font-size: 10px"
                  class="card-title"
                >
                  Powerful wave soldering system with attractive
                  price/performance ratio
                </h6>
              </div>
            </div>
          </router-link>
        </div>
        <!-- end col -->
      </div>
    </div>
    <Footer />
  </div>
</template>
<script>
import Header from "../../../../components/Header.vue";

import Footer from "../../../../components/Footer.vue";

/**
 * Dashboard component
 */
export default {
  components: {
    Header,

    Footer,
  },
};
</script>